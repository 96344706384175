import React from "react"
import {useTranslation} from "react-i18next"
import {Link} from 'react-router-dom'
import {
    faBook,
    faCog,
    faComments,
    faHistory,
    faHome,
    faImage,
    faLightbulb,
    faRightFromBracket,
    faShareAlt,
    faUpload,
    faUserPlus,
    faVideo
} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"

function Menu() {
    const {t} = useTranslation('', {keyPrefix: 'menu'})
    const menuItems = [
        {name: 'home', icon: faHome, url: '/home'},
        {name: 'works', icon: faBook, url: '/works'},
        {name: 'ideas', icon: faLightbulb, url: '/ideas'},
        // {name: 'photos', icon: faImage, url: '/construct'},
        // {name: 'videos', icon: faVideo, url: '/construct'},
        {name: 'publish', icon: faUpload, url: '/construct'},
        {name: 'history', icon: faHistory, url: '/construct'}
    ]
    const accountItems = [
        {name: 'settings', icon: faCog, url: '/construct'},
        {name: 'membership', icon: faUserPlus, url: '/construct'},
        {name: 'feedback', icon: faComments, url: '/construct'},
        {name: 'share', icon: faShareAlt, url: '/construct'},
    ]
    return (
        <div className="bg-teal1 w-20 h-screen text-white text-center">
            <div className="mb-8 mt-4">
                <img src="/images/logo.png" alt="Logo" className="mx-auto mb-4 w-16"/>
            </div>
            <div className="mb-8">
                <h2 className="text-lg mb-4 font-bold">{t('creator')}</h2>
                <ul className="space-y-2">
                    {menuItems.map(item => (
                        <li className="flex flex-col items-center" key={item.name}>
                            <Link to={item.url} className="text-white no-underline">
                                <div>
                                    <FontAwesomeIcon icon={item.icon} className="fa-2x mb-1"
                                                     style={{color: '#FAD81B'}}/>
                                </div>
                                <div>
                                    {t(item.name)}
                                </div>
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
            <hr className="border-t-2 border-white mb-8"/>
            <div>
                <h2 className="text-lg mb-4 font-bold">{t('account')}</h2>
                <ul className="space-y-2">
                    {accountItems.map(item => (
                        <li className="flex flex-col items-center" key={item.name}>
                            <Link to={item.url} className="text-white no-underline">
                                <div>
                                    <FontAwesomeIcon icon={item.icon} className="fa-2x mb-1"
                                                     style={{color: '#FAD81B'}}/>
                                </div>
                                <div>
                                    {t(item.name)}
                                </div>
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

export default Menu