import React from 'react'
import {useTranslation} from 'react-i18next'
import {useQuery} from "@apollo/client"
import {GET_SYSTEMMESSAGE} from '../../graphql/queries'
import Parser from "html-react-parser"

function Dashboard() {
    const {t, i18n} = useTranslation('', {keyPrefix: 'dashboard'})
    const {data, loading, error} = useQuery(GET_SYSTEMMESSAGE, {
        variables: {type: 'bulletin_board', locale: i18n.language}
    })

    if (loading) return <p>Loading...</p>
    if (error) return <p>Error :(</p>

    return (
        <>
            {/*<div className="flex items-center mb-4">*/}
            {/*    <h1 className="text-4xl font-bold">{t('dashboardTitle')}</h1>*/}
            {/*</div>*/}
            <div>
                {data && data.getSystemMessage && data.getSystemMessage.content && (
                    <div className="mb-4">
                        <h2 className="text-2xl font-bold">{t('bulletinBoardTitle')}</h2>
                        <p>{Parser(data.getSystemMessage.content)}</p>
                    </div>
                )}
            </div>
        </>
    )
}

export default Dashboard
