import React, {useState} from 'react'
import {useMutation} from '@apollo/client'
import {CHANGE_PASSWORD} from "../../../graphql/mutations"
import {useTranslation} from "react-i18next"

const ChangePassword = () => {
    const {t} = useTranslation('', {keyPrefix: 'changePassword'})

    const [currentPassword, setCurrentPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmNewPassword, setConfirmNewPassword] = useState('')
    const [modalTitle, setModalTitle] = useState('')
    const [modalMessage, setModalMessage] = useState('')
    const [isModalOpen, setIsModalOpen] = useState(false)

    const [changePassword, {loading}] = useMutation(CHANGE_PASSWORD, {
        onCompleted: () => {
            setModalTitle(t('passwordChangeSucceededTitle'))
            setModalMessage(t('passwordChangeSucceededMessage'))
            setIsModalOpen(true)
        },
        onError: (error) => {
            setModalMessage(error.message)
            setIsModalOpen(true)
        }
    })

    const handleSubmit = async (e: any) => {
        e.preventDefault()
        if (newPassword !== confirmNewPassword) {
            setModalTitle(t('passwordNotMatchedTitle'))
            setModalMessage(t('passwordNotMatchedMessage'))
            setIsModalOpen(true)
            return
        }

        await changePassword({
            variables: {
                changePasswordInput: {
                    currentPassword,
                    newPassword
                }
            },
        })
    }

    const closeModal = () => {
        setIsModalOpen(false)
    }

    return (
        <div className="container mx-auto p-4">
            <h1 className="text-xl font-bold mb-4">
                {t('title')}
            </h1>
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label htmlFor="current-password" className="block text-gray-700 mb-2">
                        {t('currentPassword')}
                    </label>
                    <input
                        type="password"
                        id="current-password"
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        className="w-full px-3 py-2 border rounded shadow-sm"
                        required
                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="new-password" className="block text-gray-700 mb-2">
                        {t('newPassword')}
                    </label>
                    <input
                        type="password"
                        id="new-password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        className="w-full px-3 py-2 border rounded shadow-sm"
                        required
                    />
                </div>
                <div className="mb-6">
                    <label htmlFor="confirm-new-password" className="block text-gray-700 mb-2">
                        {t('confirmPassword')}
                    </label>
                    <input
                        type="password"
                        id="confirm-new-password"
                        value={confirmNewPassword}
                        onChange={(e) => setConfirmNewPassword(e.target.value)}
                        className="w-full px-3 py-2 border rounded shadow-sm"
                        required
                    />
                </div>
                <button type="submit" className="w-full py-2 px-4 bg-gold text-white rounded hover:bg-gold-darker"
                        disabled={loading}>
                    {loading ? t('changing') : t('change')}
                </button>
            </form>

            {/* Modal for displaying messages */}
            {isModalOpen && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="my-modal">
                    <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
                        <div className="mt-3 text-center">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">{modalTitle}</h3>
                            <div className="mt-2 px-7 py-3">
                                <p className="text-sm text-gray-500">
                                    {modalMessage}
                                </p>
                            </div>
                            <div className="items-center px-4 py-3">
                                <button id="ok-btn"
                                        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
                                        onClick={closeModal}>
                                    {t('confirm')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default ChangePassword
