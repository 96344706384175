import React from 'react'
import {useAuth} from '../AuthContext'
import {useNavigate} from 'react-router-dom'

function Logout() {
    const {setIsLoggedIn} = useAuth()
    const navigate = useNavigate()

    // Remove the token from local storage
    localStorage.removeItem('token')
    // Update the logged-in state
    setIsLoggedIn(false)
    // Redirect to the login page or home page
    navigate('/login')

    return (
        <></>
    )
}

export default Logout
