import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useLazyQuery, useMutation, useQuery} from "@apollo/client"
import {GET_NEW_IDEAS, LIST_IDEAS, LIST_WORKS} from '../../graphql/queries'
import {ADD_IDEA, GENERATE_SCENE, REMOVE_IDEA, REMOVE_WORK, UPDATE_EPISODE} from '../../graphql/mutations' // Make sure to import the mutation
import {Link} from 'react-router-dom'
import {faBookmark, faFireFlameCurved, faHistory, faPenNib, faSpinner} from "@fortawesome/free-solid-svg-icons"
import {faBookmark as faRegBookmark} from "@fortawesome/free-regular-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import i18n from "i18next" // Import the delete icon

function Works() {
    const {t, i18n} = useTranslation('', {keyPrefix: 'ideas'})
    const {t: lt} = useTranslation('', {keyPrefix: 'language'})
    const [list, setList] = useState([])
    const [clues, setClues] = useState('')
    const [getNewIdeas, {data: newIdeasData, loading: getNewIdeasLoading, error: getNewIdeasError}] = useLazyQuery(GET_NEW_IDEAS, {fetchPolicy: 'network-only'})
    const [listIdeas, {data: ideasData, loading: listIdeasLoading, error: listIdeasError}] = useLazyQuery(LIST_IDEAS, {fetchPolicy: 'network-only'})
    const [addIdea, {loading: addIdeaLoading, error: addIdeaError}] = useMutation(ADD_IDEA)
    const [removeIdea, {loading: removeIdeaLoading, error: removeIdeaError}] = useMutation(REMOVE_IDEA)
    const [language, setLanguage] = useState(i18n.language)

    const getNewIdeasHandle = async () => {
        try {
            const result = await getNewIdeas({
                variables: {
                    getIdeaInput: {
                        clues: clues,
                        locale: language
                    }
                }
            })
            if (result.data && result.data.getNewIdeas) {
                setList(result.data.getNewIdeas.map((item: any) => ({
                    ...item,
                    id: ''
                })))
            }
        } catch (error) {
            console.error("Error getting new ideas:", error)
        }
    }

    const listIdeasHandle = async () => {
        try {
            const result = await listIdeas({
                variables: {}
            })
            if (result.data && result.data.listIdeas) {
                setList(result.data.listIdeas)
            }
        } catch (error) {
            console.error("Error getting ideas:", error)
        }
    }

    const saveIdeaHandle = async (idea: any) => {
        try {
            const result = await addIdea({
                variables: {
                    ideaInput: {
                        title: idea.title,
                        clues: clues,
                        abstract: idea.abstract,
                        language: idea.language ?? language
                    }
                }
            })
            idea.id = result.data.addIdea.id
        } catch (error) {
            console.error("Error adding ideas:", error)
        }
    }

    const removeIdeaHandle = async (idea: any) => {
        try {
            await removeIdea({
                variables: {
                    id: idea.id
                }
            })
            idea.id = null
        } catch (error) {
            console.error("Error removing ideas:", error)
        }
    }


    return (
        <>
            <div className="flex items-center mb-4">
                <h1 className="text-4xl font-bold">{t('ideasTitle')}</h1>
            </div>
            <div>
                <label className="block text-xl text-gray-700 mb-2 mt-4">{lt('title')}</label>
                <select className="w-full px-3 py-2 border rounded shadow-sm" value={language}
                        onChange={(e) => setLanguage(e.target.value)}
                >
                    <option value="en-US">{lt('languageOptions.English')}</option>
                    <option value="zh-CN">{lt('languageOptions.simplifiedChinese')}</option>
                </select>
                <label className="block text-xl text-gray-700 mb-2 mt-4">{t('clues')}</label>
                <textarea
                    value={clues}
                    onChange={(e) => setClues(e.target.value)}
                    rows={3}
                    className="w-full px-3 py-2 border rounded shadow-sm mt-2"
                    placeholder={t('cluesPlaceholder')}
                />
            </div>
            <div className="flex justify-between p-2">
                <button
                    onClick={getNewIdeasHandle}
                    className="w-full h-12 text-white hover:text-yellow-800 bg-teal-600 rounded-md m-4"
                    title={t('getNewIdeas')}
                >
                    <FontAwesomeIcon
                        icon={!getNewIdeasLoading ? faFireFlameCurved : faSpinner}
                        className={`${!getNewIdeasLoading ? '' : 'animate-spin'} fa-2x`}
                    />
                </button>
                <button
                    onClick={listIdeasHandle}
                    className="w-full h-12 text-white hover:text-yellow-800 bg-teal2 rounded-md m-4"
                    title={t('savedIdeas')}
                >
                    <FontAwesomeIcon
                        icon={faHistory} className="fa-2x"
                    />
                </button>
            </div>
            <ul className="space-y-4">
                {list.map((idea: any, i: number) => (
                    <li key={i} className="border rounded-md flex justify-between items-center p-8">
                        <div>
                            <h2 className="text-2xl font-semibold mb-2">{idea.title}</h2>
                            <p className="text-lg mb-2">{idea.abstract}</p>
                        </div>
                        <div>
                            <div className="flex justify-between w-8 ml-8 mr-2">
                                {idea.id && (
                                    <Link
                                        to={`/idea-work/${idea.id}`}
                                        className="text-teal-600 hover:text-red-800"
                                        title={t('edit')}
                                    >
                                        <FontAwesomeIcon icon={faPenNib} className="fa-2x"/>
                                    </Link>
                                )}
                            </div>
                        </div>
                        <div>
                            <div className="flex justify-between w-8 mr-2">
                                {idea.id ?
                                    <button
                                        onClick={() => removeIdeaHandle(idea)}
                                        className="text-teal-600 hover:text-red-800"
                                        title={t('bookmarked')}
                                    >
                                        <FontAwesomeIcon icon={faBookmark} className="fa-2x"/>
                                    </button>
                                    :
                                    <button
                                        onClick={() => saveIdeaHandle(idea)}
                                        className="text-teal-600 hover:text-red-800"
                                        title={t('bookmark')}
                                    >
                                        <FontAwesomeIcon icon={faRegBookmark} className="fa-2x"/>
                                    </button>
                                }
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        </>
    )
}

export default Works
