import React, {useEffect, useState} from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {
    faCheck,
    faCloudArrowUp,
    faEdit,
    faFireFlameCurved,
    faSpinner,
    faTimes
} from "@fortawesome/free-solid-svg-icons"
import Select from "react-select"
import ReactQuill from "react-quill"
import SceneNavigator from "./SceneNavigator"
import {useMutation, useQuery} from "@apollo/client"
import {
    COMPLETE_SCENE_CONTENT, COMPLETE_SCENE_CONVERSATIONS,
    GENERATE_SCENE, GENERATE_SCENE_STORYBOARD,
    REMOVE_SCENE,
    UPDATE_EPISODE,
    UPDATE_SCENE
} from "../../../graphql/mutations"
import {GET_ROLES, GET_SCENE} from "../../../graphql/queries"
import {useTranslation} from "react-i18next"
import AITextEditor from "../../../components/AITextEditor"

function EditorTab(props: any) {
    const {t} = useTranslation('', {keyPrefix: 'edit.editor'})
    const storyboardUrlPrefix = process.env.REACT_APP_SCENE_STORYBOARD_HOST_PREFIX
    const workId = props.workId
    const sceneId = props.sceneId
    const [scene, setScene] = useState<any | null>(null)
    const [isSceneChanged, setIsSceneChanged] = useState(false)
    const [roleOptions, setRoleOptions] = useState([])
    const [selectedRoles, setSelectedRoles] = useState<any[]>([])
    const [isEditingEpisodeTitle, setIsEditingEpisodeTitle] = useState<any>(null)
    const {data: sceneData, loading: sceneLoading, error: sceneError, refetch: refetchScene} = useQuery(GET_SCENE, {
        variables: {sceneId: sceneId},
        skip: !sceneId,
        onCompleted: () => {
            setScene({...sceneData.getScene})
        }
    })
    const {data: rolesData} = useQuery(GET_ROLES, {
        variables: {
            workId: workId,
        }, skip: !workId
    })

    const [updateEpisode, {loading: updatingEpisodeLoading, error: updatingEpisodeError}] = useMutation(UPDATE_EPISODE)
    const [updateScene, {loading: updatingSceneLoading, error: updatingSceneError}] = useMutation(UPDATE_SCENE)


    const [generateScene, {loading: generatingSceneLoading, error: generatingSceneError}] = useMutation(GENERATE_SCENE)
    const [generateSceneStoryboard, {
        loading: generatingSceneStoryboardLoading,
        error: generatingSceneStoryboardError
    }] = useMutation(GENERATE_SCENE_STORYBOARD)

    useEffect(() => {
        if (rolesData && rolesData.getRoles) {
            setRoleOptions(rolesData.getRoles.map((role: any) => ({value: role.name, label: role.name})))
        }
    }, [rolesData])

    useEffect(() => {
        if (scene && scene.roleDescription) {
            const roleNames = JSON.parse(scene.roleDescription)
            const selectedRoles = roleNames.map((roleName: any) => ({value: roleName, label: roleName}))
            setSelectedRoles(selectedRoles)
            setScene(scene)
        }
    }, [scene])

    useEffect(() => {
        let interval: NodeJS.Timeout
        if (isSceneChanged) {
            interval = setInterval(async () => {
                // Define the input for the updateScene mutation
                const updateSceneInput = {
                    id: scene.id,
                    title: scene.title,
                    roleDescription: scene.roleDescription,
                    content: scene.content,
                    conversations: scene.conversations
                }

                // Call the updateScene mutation
                await updateScene({variables: {updateSceneInput}})
                setIsSceneChanged(false)
            }, 5000)
        }
        return () => clearInterval(interval)
    }, [isSceneChanged])

    const handleRoleSelectChange = (selectedOptions: any) => {
        scene.roleDescription = JSON.stringify(selectedOptions.map((roleOption: any) => (roleOption.value)))
        setSelectedRoles(selectedOptions)
        setIsSceneChanged(true)
    }

    const handleUpdateEpisodeTitle = async () => {
        try {
            // Call the updateEpisode mutation
            await updateEpisode({
                variables: {
                    updateEpisodeInput: {
                        id: scene.id,
                        title: scene.title,
                    },
                },
            })
            setIsEditingEpisodeTitle(false)
        } catch (error) {
            console.error('Error updating episode title:', error)
        }
    }

    const [removeScene] = useMutation(REMOVE_SCENE)
    // Implement the handleRemoveScene function
    const handleRemoveScene = async () => {
        // Prompt the user for confirmation
        const confirmDelete = window.confirm(t('removeSceneConfirmation'))
        if (confirmDelete) {
            try {
                // Call the removeScene mutation with the scene ID
                await removeScene({variables: {id: scene.id}})
                // Optionally, update the UI or state here
                // For example, you might want to remove the scene from a list in the state
            } catch (error) {
                console.error('Error removing scene:', error)
            }
        }
    }
    const [completeSceneContent] = useMutation(COMPLETE_SCENE_CONTENT)

    const completeSceneContentCallBack = async (content: string, cursor: number): Promise<string> => {
        return completeSceneContent({
            variables: {
                sceneId,
                content,
                cursor,
            },
        }).then((resp) => {
            return resp.data.completeSceneContent
        }).catch((e) => {
            console.error('Error completing scene content:', e)
        })
    }

    const [completeSceneConversations] = useMutation(COMPLETE_SCENE_CONVERSATIONS)

    const completeSceneConversationsCallBack = async (conversations: string, cursor: number): Promise<string> => {
        return completeSceneConversations({
            variables: {
                sceneId,
                conversations,
                cursor,
            },
        }).then((resp) => {
            return resp.data.completeSceneConversations
        }).catch((e) => {
            console.error('Error completing scene conversations:', e)
        })
    }

    return (
        <>
            {scene && (
                <div className="w-3/4 flex flex-col space-y-4 p-4 border rounded-lg shadow-md">
                    <div className="flex justify-between text-2xl">
                        <div className="flex w-1/2">
                                    <span
                                        className="mr-2">{t('episodeOrder', {order: scene.episode.order})}</span>
                            {!isEditingEpisodeTitle ? (
                                <>
                                    <h2 className="font-bold">{scene.episode.title}</h2>
                                    <button
                                        className="ml-2 text-teal-600 hover:text-teal-800"
                                        onClick={() => setIsEditingEpisodeTitle(true)}
                                    >
                                        <FontAwesomeIcon icon={faEdit}/>
                                    </button>
                                </>
                            ) : (
                                <>
                                    <input
                                        type="text"
                                        value={scene.title}
                                        onChange={(e) => {
                                            scene.episode.title = e.target.value
                                            setIsSceneChanged(scene)
                                        }}
                                        className="px-3 py-2 border rounded shadow-sm"
                                    />
                                    <button
                                        className="ml-2 text-teal-600 hover:text-teal-800"
                                        onClick={handleUpdateEpisodeTitle}
                                    >
                                        <FontAwesomeIcon icon={faCheck}/>
                                    </button>
                                </>
                            )}
                        </div>
                        <div>
                            <button type="button"
                                    onClick={async () => {
                                        try {
                                            const response = await generateScene({variables: {sceneId: scene.id}})
                                            if (response.data && response.data.generateScene) {
                                                await refetchScene()
                                                // TO-DO: refetch doesn't work. temporarily use to refresh
                                                window.location.href = window.location.href
                                            }
                                        } catch (error) {
                                            console.error("Error generating scene:", error)
                                        }
                                    }}
                                    className="top-0 right-20 text-teal-600 hover:text-teal-800 mr-4"
                            >
                                <FontAwesomeIcon
                                    icon={!generatingSceneLoading ? faFireFlameCurved : faSpinner}
                                    className={!generatingSceneLoading ? '' : 'animate-spin'}
                                />
                            </button>
                            <button type="button"
                                    className="top-0 right-10 text-teal-600 hover:text-teal-800 mr-4"
                            >
                                <FontAwesomeIcon
                                    icon={faCloudArrowUp}
                                    style={isSceneChanged ? undefined : {color: "#ccc"}}
                                    className={isSceneChanged ? 'fa-beat-fade' : ''}
                                />
                            </button>
                            <button type="button"
                                    className="top-0 right-0 text-teal-600 hover:text-teal-800 mr-4"
                                    onClick={handleRemoveScene}
                            >
                                <FontAwesomeIcon icon={faTimes}/>
                            </button>
                        </div>
                    </div>
                    <div>
                        <label
                            className="block text-xl text-gray-700 mb-2">{t('sceneOrder', {order: scene.order})}</label>
                        <input type="text" className="w-full px-3 py-2 border rounded shadow-sm"
                               placeholder={t('sceneTitlePlaceholder')}
                               value={scene.title}
                               onChange={e => {
                                   scene.title = e.target.value
                                   setIsSceneChanged(true)
                               }}/>
                    </div>
                    <div>
                        <label
                            className="block text-xl text-gray-700 mb-2">{t('rolesLabel')}</label>
                        <Select
                            isMulti
                            value={selectedRoles}
                            options={roleOptions}
                            onChange={r => {
                                handleRoleSelectChange(r)
                            }}
                            className="w-full"
                        />
                    </div>
                    <div>
                        <label
                            className="block text-xl text-gray-700 mb-2">{t('contentLabel')}</label>
                        <AITextEditor customId={`content${sceneId}`} value={scene.content}
                                      onAIComplete={completeSceneContentCallBack}
                                      onChange={(s: string) => {
                                          scene.content = s
                                          setIsSceneChanged(true)
                                      }}
                                      placeholder={t('contentLabelPlaceholder')}/>
                    </div>
                    <div>
                        <label
                            className="block text-xl text-gray-700 mb-2">{t('conversationLabel')}</label>
                        <AITextEditor customId={`conversations${sceneId}`} value={scene.conversations}
                                      onAIComplete={completeSceneConversationsCallBack}
                                      onChange={(s: string) => {
                                          scene.conversations = s
                                          setIsSceneChanged(true)
                                      }}
                                      placeholder={t('contentLabelPlaceholder')}/>
                    </div>
                    <div>
                        <div className="flex mb-2">
                            <label
                                className="block text-xl text-gray-700">{t('storyboardTitle')}</label>
                            <button type="button"
                                    onClick={async () => {
                                        try {
                                            const response = await generateSceneStoryboard({variables: {sceneId: scene.id}})
                                            if (response.data && response.data.generateSceneStoryboard) {
                                                refetchScene().then(resp=>{
                                                    setScene(resp.data.getScene)
                                                })
                                            }
                                        } catch (error) {
                                            console.error("Error generating scene storyboard:", error)
                                        }
                                    }}
                                    className="mt-0 p-0 ml-8 text-teal-600 hover:text-teal-800 mr-4"
                            >
                                <FontAwesomeIcon size="lg"
                                                 icon={!generatingSceneStoryboardLoading ? faFireFlameCurved : faSpinner}
                                                 className={!generatingSceneStoryboardLoading ? '' : 'animate-spin'}
                                />
                            </button>
                        </div>
                        {scene.storyboards ?
                            scene.storyboards.map((storyboard: any) => (
                                <img key={storyboard.id} src={`${storyboardUrlPrefix}/${storyboard.imageUrl}`}
                                     alt={`Storyboard-${storyboard.order}`} className="max-w-full h-auto"/>
                            )) : (
                                <p>{t('noStoryboard')}</p>
                            )}
                    </div>
                    <SceneNavigator key={sceneId} scene={scene}/>
                </div>
            )}
        </>
    )
}

export default EditorTab
