import {Link, Outlet, useLocation, useNavigate} from "react-router-dom"
import React, {useState, useRef, useEffect} from "react"
import Menu from "../../components/Menu"
import LanguageSwitcher from "../../components/LanguageSwitcher"
import {useAuth} from "../../AuthContext"
import UserAvatar from "../../components/UserAvatar"
import {FaSignOutAlt, FaUser} from "react-icons/fa"
import {useTranslation} from "react-i18next"

function Workspace() {
    const {t} = useTranslation('')
    const navigate = useNavigate()
    const {isLoggedIn, currentUser} = useAuth()
    const location = useLocation()
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)
    // Define the ref with the HTMLDivElement type
    const dropdownRef = useRef<HTMLDivElement>(null)

    // Close dropdown when clicking outside
    useEffect(() => {
        function handleClickOutside(event: any) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false)
            }
        }

        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [dropdownRef])

    useEffect(() => {
        if (isLoggedIn === false) {
            navigate('/login')
        }
        if (isLoggedIn === true && location.pathname === '/') {
            navigate('/home')
        }
    }, [isLoggedIn, navigate, location.pathname])

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen)
    }

    return (
        isLoggedIn ?
            <div className="flex">
                <Menu/>
                <div className="flex-1 flex flex-col">
                    <div className="flex justify-between bg-teal2 p-4">
                        <div></div>
                        {/* This empty div is used to push the next elements to the right */}
                        <div className="flex items-center relative">
                            <button onClick={toggleDropdown}>
                                {currentUser &&
                                    <UserAvatar user={currentUser} mode="thumbnail"/>
                                }
                            </button>
                            {isDropdownOpen && (
                                <div ref={dropdownRef}
                                     className="absolute top-5 right-0 mt-2 py-2 w-48 bg-white rounded-md shadow-xl z-20">
                                    <LanguageSwitcher/>
                                    <div className="mt-4">
                                        <Link to="/profile"
                                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                            <div className="flex">
                                                <FaUser className="ml-16 mr-2"/> {t('menu.profile')}
                                            </div>
                                        </Link>
                                    </div>
                                    <Link to="/logout"
                                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                        <div className="flex">
                                            <FaSignOutAlt className="ml-16 mr-2"/> {t('menu.logout')}
                                        </div>
                                    </Link>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="p-8">
                        <Outlet/>
                    </div>
                </div>
            </div>
            : <></>
    )
}

export default Workspace
