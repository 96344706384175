import {useQuery} from "@apollo/client"
import {EXPORT_WORK} from "../../../graphql/queries"
import {Link, useParams} from "react-router-dom"
import React, {useEffect, useState} from "react"
import Parser from "html-react-parser"
import {useTranslation} from "react-i18next"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faChevronLeft, faEdit} from "@fortawesome/free-solid-svg-icons"

function Export() {
    const {t} = useTranslation('', {keyPrefix: 'export'})
    const {workId} = useParams() // Extract workId from URL
    const [work, setWork] = useState<any>(null)

    const {data: workData, loading: workLoading, error: workError} = useQuery(EXPORT_WORK, {
        variables: {workId: workId, isExporting: true},
        skip: !workId  // Skip the query if workId is null/undefined
    })

    useEffect(() => {
        if (workData && workData.getWork) {
            setWork(workData.getWork)
        }
    }, [workData])

    return (
        work && (
            <div>
                <div className="flex justify-between mb-8">
                    <div>
                        <Link to={`/edit/${work.id}`}>
                            <FontAwesomeIcon icon={faChevronLeft}/>
                        </Link>
                    </div>
                    <div/>
                </div>
                <h1 className="font-bold text-3xl">{work.title}</h1>
                <p className="italic mt-8">{work.abstract}</p>
                <p className="text-lg font-semibold mt-8">{t('rolesTitle')}</p>
                {work.roles.map((role: any) => (
                    <p key={role.id}>{role.name} : {role.description}</p>
                ))}
                <p className="font-semibold mt-8 text-2xl">{t('episodesTitle')}</p>
                {work.episodes.map((episode: any) =>
                    <div className="mt-8" key={episode.id}>
                        <p className="font-semibold text-xl">
                            {t('episodeOrder', {order: episode.order})} {episode.title}
                        </p>
                        {episode.scenes.map((scene: any) =>
                            <div key={scene.id} className="ml-6 mt-8">
                                <p className="mt-8 font-semibold text-lg">{t('sceneOrder', {order: scene.order})} {scene.title}</p>
                                <p className="mt-4">{Parser(t('sceneRoleTitle'))} {scene.roleDescription}</p>
                                <div className="mt-4">{Parser(t('sceneContentTitle'))} {scene.content ? Parser(scene.content) : ''}</div>
                                <div className="mt-4">{scene.conversations ? Parser(scene.conversations) : ''}</div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        )
    )
}

export default Export