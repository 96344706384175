import React, {useEffect} from 'react'
import './App.css'
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import Login from './pages/Login'
import Register from './pages/Register'
import Dashboard from './pages/Workspace/Dashboard'
import Edit from './pages/Workspace/Edit/Edit'
import Works from './pages/Workspace/Works'
import Logout from "./pages/Logout"
import Construction from "./pages/Workspace/Construction"
import Workspace from "./pages/Workspace/Workspace"
import Profile from "./pages/Workspace/User/Profile"
import ChangePassword from "./pages/Workspace/User/ChangePassword"
import EditProfile from "./pages/Workspace/User/EditProfile"
import Export from "./pages/Workspace/Edit/Export"
import {useTranslation} from "react-i18next"
import Ideas from "./pages/Workspace/Ideas"


function App() {
    const {i18n} = useTranslation('')
    useEffect(() => {
        let title = ''
        // not defined in tranlsations
        switch (i18n.language) {
            case 'zh-CN':
                title = process.env.REACT_APP_TITLE_KEY_ZH_CN!
                break
            default:
                title = process.env.REACT_APP_TITLE_KEY_EN_US!
                break
        }
        document.title = title
    }, [])

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Workspace/>}>
                    <Route index path="home" element={<Dashboard/>}/>
                    <Route path="construct" element={<Construction/>}/>
                    <Route path="works" element={<Works/>}/>
                    <Route path="ideas" element={<Ideas/>}/>
                    <Route path="idea-work/:ideaId" element={<Edit/>}/>
                    <Route path="edit/:workId?/:sceneId?" element={<Edit/>}/>
                    <Route path="export/:workId" element={<Export/>}/>
                    <Route path="profile" element={<Profile/>}/>
                    <Route path="change-password" element={<ChangePassword/>}/>
                    <Route path="edit-profile" element={<EditProfile/>}/>
                </Route>
                <Route path="/login" element={<Login/>}/>
                <Route path="/register" element={<Register/>}/>
                <Route path="/logout" element={<Logout/>}/>
            </Routes>
        </BrowserRouter>
    )
}

export default App
