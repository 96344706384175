import React, {useEffect, useState} from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faFireFlameCurved, faSpinner} from "@fortawesome/free-solid-svg-icons"
import {FaMinus, FaPlus} from "react-icons/fa"
import {useTranslation} from "react-i18next"
import {useLazyQuery, useMutation} from "@apollo/client"
import {
    ADD_ROLES,
    ADD_WORK,
    CLEAR_ROLES,
    GENERATE_ABSTRACT,
    GENERATE_ROLES,
    UPDATE_WORK
} from "../../../graphql/mutations"
import {useNavigate, useParams} from "react-router-dom"
import {GET_IDEA, GET_ROLES} from "../../../graphql/queries"
import {useFormik} from "formik"
import * as Yup from 'yup'

function DetailTab(props: any) {
    const {t, i18n} = useTranslation('', {keyPrefix: 'edit.details'})
    const {t: lt} = useTranslation('', {keyPrefix: 'language'})
    const {ideaId} = useParams()

    const work = props.work
    const mode = work ? "updating" : "adding"
    const navigate = useNavigate()


    const [language, setLanguage] = useState(i18n.language)
    const [roles, setRoles] = useState([{name: '', description: ''}])
    const [getIdea,] = useLazyQuery(GET_IDEA)

    const updateTitle = (newTitle: string) => {
        formik.setFieldValue('title', newTitle)
    }

    const updateAbstract = (newAbstract: string) => {
        formik.setFieldValue('abstract', newAbstract)
    }

    const updateLanguage = (newLanguage: string) => {
        formik.setFieldValue('language', newLanguage)
    }

    const updateNumOfEpisodes = (newNumOfEpisodes: number) => {
        formik.setFieldValue('numOfEpisodes', newNumOfEpisodes)
    }

    useEffect(() => {
        if (mode == "adding" && ideaId) {
            getIdea({
                variables: {
                    ideaId
                }
            }).then((result) => {
                if (result.data && result.data.getIdea) {
                    updateTitle(result.data.getIdea.title)
                    updateAbstract(result.data.getIdea.abstract)
                    updateLanguage(result.data.getIdea.language)
                }
            }).catch((e) => {
                console.log(e)
            })
        }
    }, [ideaId])

    useEffect(() => {
        if (work) {
            updateTitle(props.work.title)
            updateAbstract(props.work.abstract)
            updateLanguage(props.work.language)
            updateNumOfEpisodes(props.work.numOfEpisodes)
            getRoles({variables: {workId: work ? work.id : ''}}).then((resp:any)=>{
                if(resp && resp.data.getRoles) {
                    setRoles(resp.data.getRoles)
                }
            })
        }
    }, [props.work])

    const [getRoles, {data: roleData}] = useLazyQuery(GET_ROLES)

    const [generateRoles, {loading: generatingRolesLoading, error: generatingRolesError}] = useMutation(GENERATE_ROLES)
    const [generateAbstract, {
        loading: generatingAbstractLoading,
        error: generatingAbstractError
    }] = useMutation(GENERATE_ABSTRACT)

    const handleRoleChange = (index: number, field: 'name' | 'description', value: string) => {
        const newRoles = [...roles]
        newRoles[index][field] = value
        setRoles(newRoles)
    }
    const [addRoles] = useMutation(ADD_ROLES)
    const [clearRoles] = useMutation(CLEAR_ROLES)

    const addRole = () => {
        setRoles([...roles, {name: '', description: ''}])
    }
    const [addWork, {data, loading, error}] = useMutation(ADD_WORK)
    const [updateWork, {data: updateData, loading: updateLoading, error: updateError}] = useMutation(UPDATE_WORK)

    const handleSave = async (values: any) => {
        let isSaveSucceeded = false
        try {
            let newWorkId: string = '' // Explicitly specify the type
            if (mode === "adding") {
                const variables = {
                    workInput: {
                        title: values.title,
                        abstract: values.abstract,
                        numOfEpisodes: parseInt(values.numOfEpisodes),
                        language: values.language,
                        ideaId
                    },
                }
                const response = await addWork({variables})
                newWorkId = response.data.addWork.id
                isSaveSucceeded = true
            } else if (mode === "updating") {
                const variables = {
                    updateWorkInput: {
                        id: work.id,
                        title: values.title,
                        abstract: values.abstract,
                        numOfEpisodes: parseInt(values.numOfEpisodes),
                        language: values.language,
                    },
                }
                const response = await updateWork({variables})  // Call updateWork when in updating mode
                newWorkId = work.id!
            }

            // Handle roles
            if (newWorkId !== null) { // Ensure newWorkId is not null before proceeding
                if (mode === "updating") {
                    // If in updating mode, clear existing roles first.
                    await clearRoles({
                        variables: {
                            clearRolesInput: {
                                workId: newWorkId,
                            },
                        },
                    })
                }

                // Add roles (in both adding and updating mode).
                await addRoles({
                    variables: {
                        roles: roles.map(role => ({
                            name: role.name,
                            workId: newWorkId,
                            description: role.description,
                        })),
                    },
                })
            }

            if (isSaveSucceeded) {
                navigate(`/edit/${newWorkId}/0`)
            }

        } catch (err) {
            console.error('Error:', err)
        }
    }

    const formik = useFormik({
        initialValues: {
            title: work ? work.title : '',
            abstract: work ? work.abstract : '',
            numOfEpisodes: work ? work.numOfEpisodes.toString() : '',
            language: work ? work.language : i18n.language,
        },
        validationSchema: Yup.object({
            title: Yup.string().required(t('titleRequired')),
            numOfEpisodes: Yup.number().required(t('numOfEpisodesRequired')).positive()
                .integer().max(200, t('numOfEpisodesMax', {value: 200})),
        }),
        onSubmit: async (values) => {
            handleSave(values)
        },
    })

    return (
        <form onSubmit={formik.handleSubmit} className="space-y-4 rounded-lg border shadow-sm p-8">
            <div>
                <label className="block text-xl text-gray-700 mb-2">{t('title')}</label>
                <input
                    type="text"
                    name="title"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.title}
                    className="w-full px-3 py-2 border rounded shadow-sm"
                />
                {formik.touched.title && formik.errors.title ? (
                    <div className="text-red-500">{formik.errors.title}</div>
                ) : null}
                <div className="mt-4">
                    <label className="block text-xl text-gray-700 mb-2">{lt('title')}</label>
                    <select name="language" className="w-full px-3 py-2 border rounded shadow-sm"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.language}
                    >
                        <option value="en-US">{lt('languageOptions.English')}</option>
                        <option value="zh-CN">{lt('languageOptions.simplifiedChinese')}</option>
                    </select>
                </div>
            </div>
            <div className="mb-2 flex justify-between items-center">
                <label className="block text-xl text-gray-700 mb-2">{t('roles')}
                    {work &&
                        <button
                            type="button"
                            onClick={async () => {
                                try {
                                    const response = await generateRoles({variables: {workId: work.id}})
                                    if (response.data && response.data.generateRoles) {
                                        setRoles(response.data.generateRoles)
                                    }
                                } catch (error) {
                                    console.error("Error generating roles:", error)
                                }
                            }}
                            className="mt-8 ml-16 text-teal-600 hover:text-teal-800"
                        >
                            <FontAwesomeIcon
                                icon={!generatingRolesLoading ? faFireFlameCurved : faSpinner}
                                className={!generatingRolesLoading ? '' : 'animate-spin'}
                            />
                        </button>
                    }
                    <button
                        type="button"
                        onClick={addRole}
                        className="mt-8 ml-4 text-teal-600 hover:text-teal-800"
                    >
                        <FaPlus/>
                    </button>
                </label>
            </div>
            {roles.map((role, index) => (
                <div key={index} className="flex space-x-4 mb-4">
                    <button
                        type="button"
                        onClick={() => {
                            const newRoles = [...roles]
                            newRoles.splice(index, 1)
                            setRoles(newRoles)
                        }}
                        className="text-red-600 hover:text-red-800"
                    >
                        <FaMinus/>
                    </button>
                    <input
                        type="text"
                        value={role.name}
                        onChange={(e) => handleRoleChange(index, 'name', e.target.value)}
                        className="w-1/2 px-3 py-2 border rounded shadow-sm"
                        placeholder={t('roleNamePlaceholder')}
                    />
                    <input
                        type="text"
                        value={role.description}
                        onChange={(e) => handleRoleChange(index, 'description', e.target.value)}
                        className="w-1/2 px-3 py-2 border rounded shadow-sm"
                        placeholder={t('roleDescriptionPlaceholder')}
                    />
                </div>
            ))}
            <div>
                <label className="block text-xl text-gray-700 mb-2">{t('abstract')}
                    {work &&
                        <button
                            type="button"
                            onClick={async () => {
                                try {
                                    const response = await generateAbstract({variables: {workId: work.id}})
                                    if (response.data && response.data.generateAbstract) {
                                        updateAbstract(response.data.generateAbstract.abstract)
                                    }
                                } catch (error) {
                                    console.error("Error generating abstract:", error)
                                }
                            }}
                            className="mt-8 ml-16 text-teal-600 hover:text-teal-800"
                        >
                            <FontAwesomeIcon
                                icon={!generatingAbstractLoading ? faFireFlameCurved : faSpinner}
                                className={!generatingAbstractLoading ? '' : 'animate-spin'}
                            />
                        </button>
                    }
                </label>
                <textarea
                    name="abstract"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.abstract}
                    rows={10}
                    className="w-full px-3 py-2 border rounded shadow-sm"
                />
            </div>
            <div>
                <label className="block text-xl text-gray-700 mb-2">{t('numOfEpisodes')}</label>
                <input
                    type="number"
                    name="numOfEpisodes"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.numOfEpisodes}
                    className="w-full px-3 py-2 border rounded shadow-sm"
                />
                {formik.touched.numOfEpisodes && formik.errors.numOfEpisodes ? (
                    <div className="text-red-500">{formik.errors.numOfEpisodes}</div>
                ) : null}
            </div>
            <div className="flex justify-center space-x-4">
                <div className="flex justify-center space-x-4">
                    <button
                        type="button"
                        className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-700"
                        onClick={() => formik.resetForm()}
                    >
                        {t('resetButton')}
                    </button>
                    <button
                        type="submit"
                        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
                    >
                        {t('saveButton')}
                    </button>
                </div>
            </div>
        </form>
    )
}

export default DetailTab
