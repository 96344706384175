import React, {ChangeEvent, Component} from 'react'
import ReactQuill from 'react-quill'
import PropTypes from 'prop-types'
import {faFireFlameCurved, faSpinner} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"

/*
 * Custom "star" icon for the toolbar using an Octicon
 * https://octicons.github.io
 */
const CustomButton: React.FC<{ spinning: boolean }> = ({spinning}) => <FontAwesomeIcon icon={spinning ? faSpinner : faFireFlameCurved} size="sm" spin={spinning} />

/*
 * Custom toolbar component including insertStar button and dropdowns
 */
function CustomToolbar(props: any) {
    return (
        <div id={`toolbar${props.customId}`}>
            <select className="ql-header" defaultValue={""}
                    onChange={(e: ChangeEvent<HTMLSelectElement>) => e.persist()}>
                <option value="1"/>
                <option value="2"/>
                <option/>
            </select>
            <button className="ql-bold"/>
            <button className="ql-italic"/>
            <button className="ql-underline"/>
            <select className="ql-color">
                <option value="red"/>
                <option value="green"/>
                <option value="blue"/>
                <option value="orange"/>
                <option value="violet"/>
                <option value="#d0d1d2"/>
                <option/>
            </select>
            <button className="ql-aiComplete">
                <CustomButton spinning={props.spinning}/>
            </button>
        </div>
    )
}

interface EditorProps {
    customId: string,
    placeholder: string
    value: string
    onChange: (s: string) => void,
    onAIComplete: (content: string, cursor: number) => Promise<string>,
}

interface EditorState {
    editorHtml: string
}

interface EditorState {
    editorHtml: string
    spinning: boolean // Add this line to include the spinning property in the state type
}

/*
 * Editor component with custom toolbar and content containers
 */
class AITextEditor extends Component<EditorProps, EditorState> {
    /*
     * Event handler to be attached using Quill toolbar module
     * https://quilljs.com/docs/modules/toolbar/
     */
    private completeHandler: (text: string, cursor: number) => Promise<string>
    private reactQuillRef: any

    state = {
        editorHtml: '',
        spinning: false,
    }

    async complete(quillEditor: any) {
        quillEditor.enable(false)
        const cursorPosition = quillEditor.getSelection().index
        const text = quillEditor.getText()

        // Start spinning
        this.setState({ spinning: true })

        try {
            const completion = await this.props.onAIComplete(text, cursorPosition)
            if (completion) {
                quillEditor.insertText(cursorPosition, completion)
                quillEditor.setSelection(cursorPosition + completion.length)
            }
        } finally {
            // Stop spinning
            this.setState({ spinning: false })
            quillEditor.enable(true)
        }
    }

    modules = {
        toolbar: {
            container: `#toolbar${this.props.customId}`,
            handlers: {
                aiComplete: () => this.complete(this.reactQuillRef.getEditor()),
            }
        },
        clipboard: {
            matchVisual: false,
        }
    }

    formats = [
        "header",
        "font",
        "size",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "color"
    ]

    static propTypes = {
        placeholder: PropTypes.string
    }

    constructor(props: EditorProps) {
        super(props)
        this.reactQuillRef = null
        this.completeHandler = this.props.onAIComplete
    }

    render() {
        return (
            <div className="text-editor">
                <CustomToolbar customId={this.props.customId} spinning={this.state.spinning} />
                <ReactQuill
                    ref={(el) => {
                        this.reactQuillRef = el
                    }}
                    value={this.props.value}
                    onChange={this.props.onChange}
                    placeholder={this.props.placeholder}
                    modules={this.modules}
                    formats={this.formats}
                    theme={"snow"} // pass false to use minimal theme
                />
            </div>
        )
    }
}

export default AITextEditor
