import React from 'react'
import {useTranslation} from 'react-i18next'
import {useQuery} from "@apollo/client"
import {Link} from 'react-router-dom'
import {FaEdit} from "react-icons/fa"
import {GET_USER} from "../../../graphql/queries"
import UserAvatar from "../../../components/UserAvatar"

function Profile() {
    const {t} = useTranslation('', {keyPrefix: 'profile'})

    const {data, loading, error} = useQuery(GET_USER, {fetchPolicy: 'network-only'})

    if (loading) return <p>{t('loading')}</p>
    if (error) return <p>{t('error')}{error}</p>
    const user = data?.getUser

    return (
        <>
            <div className="items-center mb-4 text-center">
                <h1 className="text-4xl font-bold">{t('profileTitle')}</h1>
            </div>
            <div className="border p-4 rounded-md text-center">
                <h2 className="text-2xl font-semibold mb-2">{user?.name}</h2>
                <div className="flex justify-between">
                    <div></div>
                    <UserAvatar user={user}/>
                    <div></div>
                </div>
                <h2 className="text-2xl font-semibold mb-2 mt-4">{user?.firstName} {user?.lastName}</h2>
                <p className="text-lg mb-2">{user?.email}</p>
                <div className="mt-8">
                    <Link className="block w-full py-2 px-4 bg-gold rounded hover:bg-gold-darker"
                          to="/edit-profile">{t('editProfile')}
                    </Link>
                </div>
                <div className="mt-8">
                    <Link className="block w-full py-2 px-4 bg-gold rounded hover:bg-gold-darker"
                          to="/change-password">{t('changePassword')}
                    </Link>
                </div>
            </div>
        </>
    )
}

export default Profile
