import React, {useEffect, useState} from 'react'
import {useMutation, useQuery} from '@apollo/client'
import {UPDATE_USER} from "../../../graphql/mutations"
import {GET_USER} from "../../../graphql/queries"
import {useTranslation} from "react-i18next"
import {useNavigate} from "react-router-dom"

const EditProfile = () => {
    const {t} = useTranslation('', {keyPrefix: 'profile'})
    const navigate = useNavigate()
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [avatarUrl, setAvatarUrl] = useState('')
    const [modalTitle, setModalTitle] = useState('')
    const [modalMessage, setModalMessage] = useState('')
    const [isModalOpen, setIsModalOpen] = useState(false)

    const {data: userData, loading: userLoading, refetch: refetchUser} = useQuery(GET_USER)
    const [updateUser, {loading: updateLoading}] = useMutation(UPDATE_USER, {
        onCompleted: () => {
            setModalTitle(t('updateSucceededTitle'))
            setModalMessage(t('updateSucceededMessage'))
            setIsModalOpen(true)
        },
        onError: (error) => {
            setModalTitle(t('updateSucceeded'))
            setModalMessage(error.message)
            setIsModalOpen(true)
        }
    })

    useEffect(() => {
        if (userData) {
            setName(userData.getUser.username)
            setFirstName(userData.getUser.firstName)
            setLastName(userData.getUser.lastName)
            setEmail(userData.getUser.email)
            setAvatarUrl(userData.getUser.avatar || '')
        }
    }, [userData])

    const handleSubmit = async (e: any) => {
        e.preventDefault()
        await updateUser({
            variables: {
                updateUserInput: {
                    firstName,
                    lastName,
                    email,
                    avatarUrl
                }
            },
        })
        navigate('/profile')
        // Refreshing
        window.location.href = window.location.href
    }

    const closeModal = () => {
        setIsModalOpen(false)
    }

    if (userLoading) {
        return <div>{t('loading')}</div>
    }

    return (
        <div className="container mx-auto p-4">
            <h1 className="text-xl font-bold mb-4">{t('editProfile')}</h1>
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label htmlFor="first-name" className="block text-gray-700 mb-2">
                        {t('firstName')}
                    </label>
                    <input
                        type="text"
                        id="first-name"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        className="w-full px-3 py-2 border rounded shadow-sm"
                        required
                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="last-name" className="block text-gray-700 mb-2">
                        {t('lastName')}
                    </label>
                    <input
                        type="text"
                        id="last-name"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        className="w-full px-3 py-2 border rounded shadow-sm"
                        required
                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="name" className="block text-gray-700 mb-2">
                        {t('username')}
                    </label>
                    <input
                        type="text"
                        id="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="w-full px-3 py-2 border rounded shadow-sm"
                        required
                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="email" className="block text-gray-700 mb-2">
                        {t('email')}
                    </label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="w-full px-3 py-2 border rounded shadow-sm"
                        required
                    />
                </div>
                {/*<div className="mb-6">*/}
                {/*    <label htmlFor="avatar" className="block text-gray-700 mb-2">*/}
                {/*        Avatar URL*/}
                {/*    </label>*/}
                {/*    <input*/}
                {/*        type="text"*/}
                {/*        id="avatar"*/}
                {/*        value={avatar}*/}
                {/*        onChange={(e) => setAvatar(e.target.value)}*/}
                {/*        className="w-full px-3 py-2 border rounded shadow-sm"*/}
                {/*    />*/}
                {/*</div>*/}
                <button type="submit" className="w-full py-2 px-4 bg-gold text-white rounded hover:bg-gold-darker"
                        disabled={updateLoading}>
                    {updateLoading ? t('updating') : t('update')}
                </button>
            </form>

            {/* Modal for displaying messages */}
            {isModalOpen && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="my-modal">
                    <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
                        <div className="mt-3 text-center">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">{modalTitle}</h3>
                            <div className="mt-2 px-7 py-3">
                                <p className="text-sm text-gray-500">
                                    {modalMessage}
                                </p>
                            </div>
                            <div className="items-center px-4 py-3">
                                <button id="ok-btn"
                                        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
                                        onClick={closeModal}>
                                    {t('confirm')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default EditProfile
