import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom' // Import useParams
import {LIST_EPISODES} from '../../../graphql/mutations'
import Menu from '../../../components/Menu'
import LanguageSwitcher from '../../../components/LanguageSwitcher'
import {useQuery} from "@apollo/client"
import StructureList from "./StructureList"
import DetailTab from "./DetailTab"
import EditorTab from "./EditorTab"
import {GET_SCENE, GET_WORK} from "../../../graphql/queries"

function Edit() {
    const {t} = useTranslation('', {keyPrefix: 'edit'})
    const {workId, sceneId} = useParams() // Extract workId from URL
    const [episodes, setEpisodes] = useState(Array())

    const mode = workId ? "updating" : "adding"
    const [activeTab, setActiveTab] = useState("")

    useEffect(() => {
        if(mode == "updating" && sceneId != '0')
            setActiveTab('editor')
        else
            setActiveTab("details")
    }, [workId])

    const {data: workData, loading: workLoading, error: workError} = useQuery(GET_WORK, {
        variables: {workId: workId},
        skip: !workId  // Skip the query if workId is null/undefined
    })

    const {data: sceneData, loading: sceneLoading, error: sceneError, refetch: refetchScene} = useQuery(GET_SCENE, {
        variables: {sceneId: sceneId},
        skip: !sceneId && sceneId != '0'
    })

    // Fetch episodes
    const {
        data: episodesData,
        loading: episodesLoading,
        error: episodesError,
        refetch: refetchEpisodes
    } = useQuery(LIST_EPISODES, {
        variables: {workId: workId},
        skip: !workId,  // Skip the query if workId is null/undefined
        onCompleted: () => {
            setEpisodes(episodesData.listEpisodes)
        }
    })

    return (
        <>
            <div className="flex-1">
                <div className="tabs flex mb-4 border-b">
                    {mode === "updating" && (
                        <button
                            className={`py-2 px-4 ${activeTab === 'editor' ? 'border-b-2 border-blue-500' : ''}`}
                            onClick={() => setActiveTab('editor')}
                        >
                            {t('tabEditor')}
                        </button>
                    )}
                    <button
                        className={`py-2 px-4 ${activeTab === 'details' ? 'border-b-2 border-blue-500' : ''}`}
                        onClick={() => setActiveTab('details')}
                    >
                        {t('tabDetails')}
                    </button>
                </div>
                {activeTab === 'editor' && (
                    <div className="flex">
                        <div className="w-1/4 mr-4">
                            <StructureList key={workId} work={workData ? workData.getWork : null}
                                           episodes={episodes} refetchEpisodes={refetchEpisodes}
                                           scene={sceneData ? sceneData.getScene : null}/>
                        </div>
                        <EditorTab key={sceneId} workId={workId} sceneId={sceneId}/>
                    </div>
                )}
                {activeTab === 'details' && (
                    <DetailTab key={workId} work={workData ? workData.getWork : null}/>
                )}
            </div>
        </>
    )
}

export default Edit