// queries.ts
import {gql} from '@apollo/client'

export const GET_NEW_IDEAS = gql`
  query GetNewIdeas($getIdeaInput: GetIdeaInput!) {
    getNewIdeas(getIdeaInput: $getIdeaInput) {
      title
      abstract
    }
  }
`

export const LIST_IDEAS = gql`
  query ListIdeas {
    listIdeas {
      id
      title
      abstract
      language
    }
  }
`

export const GET_IDEA = gql`
  query GetIdea($ideaId: String!) {
    getIdea(id: $ideaId) {
      id
      title
      abstract
      language
    }
  }
`

export const GET_USER = gql`
  query GetUser {
    getUser {
      id
      username
      email
      firstName
      lastName
      avatarUrl
    }
  }
`

export const LIST_WORKS = gql`
  query ListWorks {
    listWorks {
      id
      title
      abstract
      language
      numOfEpisodes
      createdAt
    }
  }
`

export const GET_WORK = gql`
  query GetWork($workId: String!) {
    getWork(id: $workId) {
      id
      title
      abstract
      language
      numOfEpisodes
    }
  }
`

export const EXPORT_WORK = gql`
  query GetWork($workId: String!, $isExporting: Boolean) {
    getWork(id: $workId, isExporting:$isExporting) {
      id
      title
      abstract
      language
      numOfEpisodes
      roles {
            id
            name
            description
            }
      episodes{
          id
          title
          order
            scenes {
                id
                title
                order
                roleDescription
                content
                conversations
            }
      }
    }
  }
`

export const GET_ROLES = gql`
    query GetRoles($workId: String!) {
        getRoles(workId: $workId) {
            id
            name
            description
            work {
                id
            }
        }
    }
`

export const LIST_SCENES = gql`
    query ListScenes($episodeId: String!) {
        listScenes(episodeId: $episodeId) {
            id
            title
            order
        }
    }
`

export const GET_SCENE = gql`
    query GetScene($sceneId: String!) {
        getScene(id: $sceneId) {
            id
            title
            order
            roleDescription
            content
            conversations
            episode {
                id
                title
                order
                work {
                    id
                }
            }
            storyboards {
                id
                imageUrl
                order
            }
        }
    }
`

export const GET_SYSTEMMESSAGE = gql`
  query GetSystemMessage($type: String!, $locale: String!) {
    getSystemMessage(type: $type, locale: $locale) {
      type
      locale
      content
      updatedAt
    }
  }
`