import React, {useState} from "react"
import {useQuery} from "@apollo/client"
import {LIST_SCENES} from "../../../graphql/queries"
import {Link} from "react-router-dom"
import {faCaretLeft, faCaretRight} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"

function SceneNavigator(props: any) {
    const scene = props.scene
    const [previousScene, setPreviousScene] = useState<any>(null)
    const [nextScene, setNextScene] = useState<any>(null)

    const {data: scenesData, refetch: refetchScenes} = useQuery(LIST_SCENES, {
        variables: {episodeId: scene.episode.id},
        skip: !scene || !scene.episode,
        onCompleted: () => {
            setPreviousScene(scene.order > 1 ? scenesData.listScenes[scene.order - 2] : null)
            setNextScene(scene.order < scenesData.listScenes.length ? scenesData.listScenes[scene.order] : null)
        }
    })

    if (scene) {
        return (
            <div className="flex justify-between">
                <div className="w-1/2">
                    {previousScene && (
                        <Link to={`/edit/${scene.episode.work.id}/${previousScene.id}`}>
                            <FontAwesomeIcon icon={faCaretLeft} className="mr-1"/>
                            {previousScene.order} {previousScene.title}
                        </Link>
                    )}
                </div>
                <div className="w-1/2 text-right">
                    {nextScene && (
                        <Link to={`/edit/${scene.episode.work.id}/${nextScene.id}`}>
                            {nextScene.order} {nextScene.title}
                            <FontAwesomeIcon icon={faCaretRight} className="ml-1"/>
                        </Link>
                    )}
                </div>
            </div>
        )
    } else return <></>
}

export default SceneNavigator
