import React from 'react'
import {useTranslation} from 'react-i18next'
import {useMutation, useQuery} from "@apollo/client"
import {LIST_WORKS} from '../../graphql/queries'
import {REMOVE_WORK} from '../../graphql/mutations' // Make sure to import the mutation
import {Link} from 'react-router-dom'
import {FaEdit, FaPlus, FaTrashAlt} from "react-icons/fa" // Import the delete icon

function Works() {
    const {t} = useTranslation('', {keyPrefix: 'works'})

    const {data, loading, error} = useQuery(LIST_WORKS, {fetchPolicy: 'network-only'})
    const [removeWork] = useMutation(REMOVE_WORK, {
        // Add refetchQueries or update cache to reflect the changes in the UI
        refetchQueries: [{query: LIST_WORKS}],
    })
    const handleRemoveWork = (id: string) => {
        if (window.confirm(t('confirmDelete'))) {
            // Call the mutation
            removeWork({variables: {id}})
        }
    }

    const formatDate = (dateString: string) => {
        const date = new Date(dateString)
        const year = date.getFullYear()
        const month = (date.getMonth() + 1).toString().padStart(2, '0')
        const day = date.getDate().toString().padStart(2, '0')
        return `${year}-${month}-${day}`
    }

    if (loading) return <p>{t('loading')}</p>
    if (error) return <p>{t('error')}</p>

    return (
        <>
            <div className="flex items-center mb-4">
                <h1 className="text-4xl font-bold">{t('worksTitle')}</h1>
                <Link
                    to="/edit"
                    className="ml-4 text-teal-600 hover:text-teal-800"
                >
                    <FaPlus/>
                </Link>
            </div>
            <ul className="space-y-4">
                {data?.listWorks.map((work: any) => (
                    <li key={work.id} className="border rounded-md flex justify-between items-center p-8">
                        <div>
                            <Link
                                to={`/edit/${work.id}`}
                                className="text-teal-600 hover:text-teal-800 underline"
                            >
                                <h2 className="text-2xl font-semibold mb-2">{work.title}</h2>
                            </Link>
                            <p className="text-lg mb-2">{formatDate(work.createdAt)}</p>
                        </div>
                        <div>
                            <div className="flex justify-between w-8 mr-8">
                                <button
                                    onClick={() => handleRemoveWork(work.id)}
                                    className="text-red-600 hover:text-red-800"
                                    title={t('delete')}
                                >
                                    <FaTrashAlt size={30}/>
                                </button>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        </>
    )
}

export default Works
